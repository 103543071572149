<template>
  <table class="table table-striped table-hover">
<!--    <col style="width:5%" class="d-none d-md-block">-->
<!--    <col style="width:10%">-->
<!--    <col style="width:15%" class="d-none d-md-block">-->
<!--    <col style="width:35%">-->
<!--    <col style="width:20%">-->
<!--    <col style="width:5%">-->
    <thead>
    <tr>
      <th class="d-none d-md-block">ID</th>
      <th>Author</th>
      <th class="d-none d-md-block">Submitted</th>
      <th>Title</th>
      <th class="text-right">Tags</th>
      <th class="text-right"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="sub in submission_list" :key="sub.id"
        @click="loadSubmission(sub)"
        style="cursor: pointer">
      <td v-if="indent" :style="'min-width:'+30*sub.nest_level+'px;padding-left:'+(12+15*sub.nest_level)+'px'" class="d-none d-md-block">
        <b-icon v-show="sub.parent_id!==null" icon="arrow90deg-up" ></b-icon>{{sub.id}}
      </td>
      <td v-else> {{sub.id}}</td>
      <td>{{sub.author_username}}</td>
      <td class="d-none d-md-block">{{new Date(sub.datetime_submitted*1000).toLocaleString()}}
        <b-icon v-if="sub.from_email" icon="envelope" title="Submitted via email"></b-icon>
      </td>
      <td v-if="link_prefix"><router-link :to="link(sub)">{{sub.draft ? `[draft] ${sub.headers.title}` : sub.headers.title}}</router-link>
      </td>
      <td v-else>{{sub.draft ? `[draft] ${sub.headers.title}` : sub.headers.title}}</td>
      <td class="text-right">
        <div>
          <Tag v-for="tag in sub.tags" :key="tag.id" :id="tag.id" :description="tag.description" :color="tag.color"></Tag>
        </div>
      </td>
      <td class="text-right">
        <b-icon icon="paperclip" v-if="sub.resources.length > 0"></b-icon>
      </td>
    </tr>
    </tbody>
  </table>

</template>

<script>
import Tag from "./Tag"
export default {
  name: "SubmissionList",
  props: [
    'submission_list',
    'link_prefix',
    'indent',
  ],
  components: {
    Tag,
  },
  methods: {
    link(sub) {
      return `${this.link_prefix}${sub.id}`;
    },
    loadSubmission(sub) {
      if (this.link_prefix) {
        this.$router.push(this.link(sub));
      } else {
        this.$emit('sub-select', sub);
      }
    },
  },
}
</script>

<style scoped>
a {
  color: #212529;
}
</style>