<template>
  <div>
    <b-row>
      <b-col v-if="draft_idx !== null" cols="12">
        <b-card no-body>
          <b-card-header>
            <div style="display:flex">
              <b-button variant="primary" size="sm" @click="openDraftList"><b-icon-arrow-left></b-icon-arrow-left></b-button>
              <b-form-input
                  id="input-log-title"
                  v-model="draft.headers.title"
                  placeholder="Log Title"
                  @input="update"
              ></b-form-input>
            </div>
            <div>
              Draft ID: {{draft.id}}
            </div>
            <div v-if="draft.task_id !== null">
              Task: <b-link :to="'/task/'+draft.task_id"> {{draft.task_id}}</b-link>
            </div>
            <div v-show="(draft.headers.original_id || null) !== null">Editing
              <b-link :to="'/submission/'+draft.headers.original_id">#{{draft.headers.original_id}}</b-link>
            </div>
            <div v-show="draft.parent_id !== null">Replying to
              <b-link :to="'/submission/'+draft.parent_id">#{{draft.parent_id}}</b-link>
            </div>

          </b-card-header>
          <div @drop="onDrop"
               @dragover="onDragover"
               @dragleave="onDragleave"
               @paste="onPaste"
               :class="{dragged_over: is_dragover}"
               id="editor">
            <v-md-editor v-model="draft.content"
                         ref="editor"
                         height="600px"
                         left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link code"
                         @change="update"
            ></v-md-editor>
            <span style="padding-left: 5px"><b-icon icon="markdown"/><small>
              Styling with <a href="https://www.markdownguide.org/cheat-sheet/">Markdown</a> is supported
            </small></span>
            <span  v-if="time_last_saved !== null" style="float: right"><small>
              Last saved {{time_last_saved_relative}}
            </small></span>

          </div>
          <b-card-footer>
            <b-row style="margin-bottom: 5px">
              <b-col>
                Tags: <Tag v-for="tag in draft.tags" :key="tag.id" :id="tag.id" :description="tag.description"
                           :color="tag.color" :remove="removeTag" ></Tag>
                <b-button pill size="sm" id="popover-reactive" variant="primary" ref="button">Add Tag</b-button>
                <b-popover
                    target="popover-reactive"
                    triggers="click"
                    :show.sync="tag_popover_show"
                    placement="auto"
                    container="tag-container"
                    ref="popover"
                >
                  <template #title>
                    <div>
                      <b-button @click="tag_popover_show=false" class="close" aria-label="Close">
                        <b-icon icon="x-circle" class="mb-2" font-scale="0.8"></b-icon>
                      </b-button>
                      Available Tags
                    </div>
                  </template>
                  <div v-for="tag in tags" :key="tag.id" @click="addTag(tag)" style="cursor: pointer; padding-bottom: 4px">
                    <Tag :id="tag.id" :color="tag.color"></Tag>
                  </div>
                </b-popover>
              </b-col>
            </b-row>
            <b-row style="margin-bottom: 5px">
              <b-col>
                <b-form-file
                    v-model="staged_file"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @input="uploadFiles([staged_file])"
                ></b-form-file>
              </b-col>
            </b-row>
            <b-card  no-body header="Attachments" v-show="draft.resources.length > 0">
              <b-list-group flush>
                <b-list-group-item v-for="(attachment, index) in draft.resources" :key="attachment.id">
                  <b-link v-on:click="removeUpload(attachment.id)"><b-icon icon="x-circle-fill" scale="1" variant="danger"></b-icon></b-link>
                  [{{index+1}}] <b-link :href="attachment.url" target="_blank" rel="noopener noreferrer">{{attachment.original_filename}} ({{Math.ceil(10*attachment.size_bytes / 1024)/10}} kB)</b-link>
                  <b-icon :id="`copy-${index}`" icon="files" scale="1.0" @click="copyAttachmentLinkToClipboard(attachment)" style="cursor: pointer"></b-icon>
                  <b-popover
                      :target="`copy-${index}`"
                      placement="top"
                      triggers="focus"
                      content="Markdown copied to clipboard!"
                  ></b-popover>
                </b-list-group-item>
              </b-list-group>
            </b-card>
            <b-button-group>
              <div id="submit-button" v-if="draft.tags.length === 0">
                <b-button variant="success" disabled>Publish</b-button>
                <b-popover
                    v-if="draft.tags.length === 0"
                    target="submit-button"
                    placement="top"
                    triggers="hover focus click" >
                  At least one tag is required
                </b-popover>
              </div>
              <div id="submit-button2" v-else-if="draft.headers.title === ''">
                <b-button variant="success" disabled>Publish</b-button>
                <b-popover
                    target="submit-button2"
                    placement="top"
                    triggers="hover focus click" >
                  Submissions require a subject
                </b-popover>
              </div>
              <b-button v-else v-on:click="publishDraft" variant="success">Publish</b-button>
              <b-button v-b-modal:delete-confirmation variant="danger">Delete Draft</b-button>
              <b-modal id="delete-confirmation" ok-title="Delete" ok-variant='danger'
                       title="Delete Confirmation" @ok="deleteDraft(draft)">
                <p>Confirm deletion of Draft #{{draft.id}} "{{draft.headers.title || 'No Title'}}"</p>
              </b-modal>

            </b-button-group>
            <b-checkbox inline v-model="suppress_email" style="margin-left: 10px">Suppress Email Notifications</b-checkbox>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col v-else>
        <b-button-group>
          <b-button @click="createDraft" variant="primary">Create new submission</b-button>
          <b-dropdown variant="primary" right>
            <b-dropdown-header>Create from template

              <b-dropdown-item v-for="template in templates"
                               :key="template.id"
                               @click="createDraftFromTemplate(template)"> {{template.name}}
<!--                <b-button size="sm"-->
<!--                          variant="outline-warning"-->
<!--                          style="height:30px;align-self:center"-->
<!--                          @click="editTemplate(template)"><b-icon-pencil></b-icon-pencil>-->
<!--                </b-button>-->
              </b-dropdown-item>
            </b-dropdown-header>
            <b-dropdown-item variant="success" @click="createNewTemplate()">New template</b-dropdown-item>
          </b-dropdown>
          <b-modal  id="fill-template-modal" ok-title="create draft"
                    @ok="createDraft()" @cancel="template_idx = null">
            <template #modal-header>
              <b-col v-if="template_idx !== null">
                <b-row>
                  <b-col cols="12">
                    <h3  style="text-align:center" >{{templates[template_idx].name}}</h3>
                    <p  style="text-align:left" >Template owner: {{templates[template_idx].username}}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button-group v-if="templates[template_idx].username === current_user.username">
                      <b-button size="sm" variant="warning" @click="editTemplate(templates[template_idx])">
                        <b-icon-pencil-square></b-icon-pencil-square>
                      </b-button>
                      <b-button size="sm" variant="danger" v-b-modal="'template-delete-confirmation'">
                        <b-icon-trash></b-icon-trash>
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
                <b-modal id="template-delete-confirmation" ok-title="Delete" ok-variant='danger'
                         title="Delete Confirmation" @ok="deleteTemplate(templates[template_idx])">
                  <p class="my-4" v-if="template_idx !== null">
                    Confirm deletion of Template #{{templates[template_idx].name}}
                  </p>
                </b-modal>
              </b-col>
            </template>
            <div v-if="template_idx !== null">
                <b-row  v-for="field in templates[template_idx].fields" :key="field">
                  <b-col cols="3">
                    {{field}}
                  </b-col>
                  <b-col cols="9">
                    <b-input v-model="template_fields[field]"></b-input>
                  </b-col>
                </b-row>
            </div>
          </b-modal>
          <b-modal  id="edit-template-modal" ok-title="save template" title="Edit Template"
                    @ok="saveTemplate()" @cancel="template_edit=null">
            <div v-if="template_edit != null">
              <b-form-group label="Template Name">
                <b-form-input v-model="template_edit.name"></b-form-input>
              </b-form-group>
              <b-form-group label="Title">
                <b-form-input v-model="template_edit.title"></b-form-input>
              </b-form-group>
              <b-form-group label="Content">
                <b-form-textarea v-model="template_edit.content"></b-form-textarea>
              </b-form-group>
            </div>
            <p>Indicate placeholders with double braces. Eg. <pre v-pre>{{identifier}}</pre></p>

          </b-modal>
        </b-button-group>
        <div v-if="drafts.length>0">
          <h2>Drafts</h2>
          <SubmissionList :submission_list="drafts" v-on:sub-select="startEdit($event)"></SubmissionList>
        </div>
        <div v-else class="text-center">
          You don't have any drafts. Click "Create new submission" to start editing one.
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SubmissionList from "@/components/SubmissionList";
const _ = require('lodash');
import http from "../http-common"
// import process_markdown from "../markdown"
import Tag from './Tag'
import {DateTime} from "luxon";

export default {
  name: "EditDraft",
  components: {
    SubmissionList,
    Tag,
  },
  props: [
    'tags',
    'current_user',
    'loggedIn',
  ],
  data() {
    return {
      drafts: [],
      draft_idx: null,
      templates: [],
      template_idx: null,
      template_fields: [],
      template_edit: null,
      staged_file: null,
      suppress_email: false,
      tag_popover_show: false,
      is_dragover: false,
      upload_in_progress: false,
      time_last_saved: null,
      time_last_saved_relative: '',
      interval: null,
    };
  },
  computed: {
    // compiledMarkdown() {
    //   return process_markdown(this.draft.content);
    // },
    draft() {
      if (this.draft_idx === null) {
        return {
          id: null,
          author_username: '',
          author_full_name: '',
          datetime_last_edited: null,
          datetime_submitted: null,
          headers: {
            title: '',
            original_id: null,
          },
          tags: [],
          content: '',
          resources: [],
          public: false,
          draft: true,
          removed: false,
          parent_id: null,
          task_id: null,
          task_title: '',
        };
      } else {
        return this.drafts[this.draft_idx];
      }
    },
  },
  methods: {
    loadDrafts() {
      this.draft_idx = null;
      const loader = this.$loading.show();
      http.get('submission/drafts')
      .then((resp) => {
        this.drafts.splice(0, this.drafts.length);
        for(let idx in resp.data) {
          this.drafts.push(resp.data[idx]);
        }
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      });
    },
    loadTemplates() {
      const loader = this.$loading.show();
      http.get('submission-template/')
          .then((resp) => {
            this.templates.splice(0, this.templates.length);
            for(let idx in resp.data) {
              this.templates.push(resp.data[idx]);
            }
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            loader.hide();
          });
    },
    createDraftFromTemplate(template) {
      for (let i=0; i<this.templates.length; i++) {
        if (template.id === this.templates[i].id) {
          this.template_idx = i;
          break;
        }
      }
      this.template_fields.splice(0, this.template_fields.length);
      template.fields.forEach((field) => {
        this.template_fields[field] = "";
      });
      this.$bvModal.show('fill-template-modal');
    },
    createNewTemplate() {
      http.post('submission-template/')
          .then((resp) => {
            this.templates.push(resp.data);
            this.editTemplate(resp.data);
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          });
    },
    editTemplate(template) {
      this.$bvModal.hide('fill-template-modal');
      this.template_edit = template;
      this.$bvModal.show('edit-template-modal');
    },
    deleteTemplate(template) {
      this.$bvModal.hide('fill-template-modal');
      this.template_idx = null;
      http.delete(`submission-template/${template.id}`)
          .then(() => {
            this.loadTemplates();
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          });
    },
    saveTemplate() {
      this.$bvModal.hide('edit-template-modal');
      let tag_ids = [];
      this.template_edit.tags.forEach((tag) => {
        tag_ids.push(tag.id);
      });
      http.put(`submission-template/${this.template_edit.id}`, {
        name: this.template_edit.name,
        title: this.template_edit.title,
        content: this.template_edit.content,
        tags: tag_ids,
      })
      .then(() => {
        this.template_edit = null;
        this.loadTemplates();
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      });

    },
    startEdit(draft) {
      for (let i=0; i<this.drafts.length; i++) {
        if (draft.id === this.drafts[i].id) {
          this.draft_idx = i;
          break;
        }
      }
    },
    syncDraft(keep_content=false) {
      http.get(`submission/draft/${this.draft.id}`)
          .then((resp) => {
            if (keep_content) {
              resp.data.content = this.draft.content;
              resp.data.headers.title = this.draft.headers.title;
            }
            Object.assign(this.draft, resp.data);
          })
          .catch((err) => {
            http.std_error_handler(this.loginExpired, err)
          });
    },
    updateDraft() {
      return http.put(`submission/draft/${this.draft.id}`,
          {
            headers: this.draft.headers,
            parent_id: this.draft.parent_id,  // in case of reply post
            task_id: this.draft.task_id,
            content: this.draft.content,
            tags: this.draft.tags,
          })
          .then((resp) => {
            // Preserve content and title in case user has entered changes since save was initiated
            resp.data.content = this.draft.content;
            resp.data.headers.title = this.draft.headers.title;
            Object.assign(this.draft, resp.data);
            this.time_last_saved = DateTime.now();
            this.updateTimeSinceLastSave();
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          });
    },
    publishDraft() {
      const loader = this.$loading.show()
      this.updateDraft().then(() => {
            http.post(`submission/publish-draft/${this.draft.id}`, {},
                {params:{suppress_email: this.suppress_email}})
                .then(() => {
                  if (this.draft.task_id === null) {
                    this.$router.push("/");
                  } else {
                    this.$router.push("/task/"+this.draft.task_id);
                  }
                })
                .finally(() => {
                  loader.hide();
                });
          }
      );
    },
    createDraft() {
      let template_data = null;
      if (this.template_idx !== null) {
        let template = this.templates[this.template_idx];
        template_data = {
          template_id: template.id,
          fields: {},
        };
        template.fields.forEach((field) => {
          template_data.fields[field] = this.template_fields[field];
        });
      }
      http.post('submission/draft', template_data)
      .then((resp) => {
        this.drafts.push(resp.data);
        this.draft_idx = this.drafts.length - 1;
        this.template_idx = null;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      });
    },
    update: _.debounce(
        function () {
          this.updateDraft();
        },
        300),
    deleteDraft(draft) {
      http.delete(`submission/draft/${draft.id}`)
          .then(() => {
            this.loadDrafts();
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
    },
    onPaste(evt) {
      if (evt.clipboardData && evt.clipboardData.items) {
        const files = [];
        evt.clipboardData.items.forEach((item) => {
          const f = item.getAsFile();
          if (f !== null) {
            files.push(f);
          }
        });
        if (files.length > 0) {
          evt.preventDefault();
          this.uploadFiles(files, true);
        }
      }
    },
    onDrop(evt) {
      if (evt.dataTransfer.files.length > 0) {
        evt.preventDefault();
        this.uploadFiles(evt.dataTransfer.files, true);
      }
    },
    onDragover() {
      this.is_dragover = true;
    },
    onDragleave() {
      this.is_dragover = false;
    },
    uploadFiles(files, insert_links=false) {
      const loader = this.$loading.show();
      http.upload_files(files,
          (res_id) => {
            return http.put(`submission/${this.draft.id}/resource`, {}, {params: {resource_id: res_id}})
                .catch((err) => {
                  http.std_error_handler(this, err);
                });
          },
          (upload_infos) => {
            if (insert_links) {
              console.log(upload_infos);
              const md_links = [''];
              upload_infos.forEach((obj) => {
                if (obj.is_image) {
                  md_links.push(`![${obj.original_filename}](${obj.inline_url})`);
                } else {
                  md_links.push(`[${obj.original_filename}](${obj.url})`);
                }
              });
              const cmi = this.$refs.editor.codemirrorInstance;
              cmi.replaceRange(md_links.join('\n'),
                  cmi.getCursor('to')
              );
            }
            this.updateDraft();
            this.staged_file = null;
            loader.hide();
          }
      );
    },
    removeUpload(upload_id) {
      const loader = this.$loading.show();
      http.delete(`submission/${this.draft.id}/resource`,
          { params: { resource_id: upload_id, } })
          .then(() => {
            this.syncDraft();
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            loader.hide();
          });
    },
    copyAttachmentLinkToClipboard(attachment) {
      let md;
      if (attachment.inline_url !== undefined) {
         md = `![${attachment.original_filename}](${attachment.inline_url})`;
      } else {
        md = `[${attachment.original_filename}](${attachment.url})`;
      }
      navigator.clipboard.writeText(md);
    },
    addTag(tag) {
      if (this.draft.tags.find((t) => {return t.id === tag.id}) === undefined) {
        this.draft.tags.push(tag);
        this.updateDraft();
      }
      this.tag_popover_show = false;
    },
    removeTag(tag_idx) {
      this.draft.tags.splice(tag_idx, 1);
      this.updateDraft();
    },
    updateTimeSinceLastSave() {
      if (this.time_last_saved !== null) {
        this.time_last_saved_relative = this.time_last_saved.toRelative();
      }
    },
    openDraftList() {
      this.draft_idx = null;
    },
  },
  created() {
    this.$emit('sync-tags');
    if (this.$route.name === "draft") {
      this.loadDrafts();
      this.loadTemplates();
    } else {
      let params = {};
      if (this.$route.name === "edit") {
        params = {original_id: this.$route.params.id};
      } else if (this.$route.name === "reply") {
        params = {parent_id: this.$route.params.id};
      } else if (this.$route.name === "update-task") {
        params = {task_id: this.$route.params.id};
      }
      const loader = this.$loading.show();
      http.post('/submission/draft', null, {params: params})
      .then((resp) => {
        this.drafts.splice(0, this.drafts.length, resp.data);
        this.draft_idx = 0;
      })
      .finally(() => {
        loader.hide();
      });
    }
    this.interval = setInterval(this.updateTimeSinceLastSave, 5000);
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }
}
</script>

<style scoped>
#editor {
  /*height: 20em;*/
}

img {
  max-width: 600px;
}
.tagListItem {
  cursor: pointer;
}

.tagListItem:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.dragged_over .drag-and-drop {
  box-shadow: #c9ff00 0 0 3px;
}


</style>

<style>
.tab-label {
  color: rgba(150,150,160,1) !important;
}

.tab-label.active {
  color: rgba(50,50,50,1) !important;
}

.submission blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
.submission blockquote:before {
  color: #ccc;
  /*content: ">";*/
  font-size: 2em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.submission blockquote p {
  display: inline;
}
</style>